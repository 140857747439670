<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <div class="p-d-flex">
                    <h5><i class="pi pi-key"></i> Regras de Acesso</h5>
                    <btn-refresh @click="obterRegras()"></btn-refresh>
                </div>
                <regras-associacoes
                    :regrasAssociadas="regrasAssociadas"
                    :regrasDisponiveis="regrasDisponiveis"
                    regraAtualizacao="CTRLACS-PA-06"
                    @associar="associar($event)"
                    @desassociar="desassociar($event)"
                ></regras-associacoes>
            </div>
        </div>
    </div>
</template>

<script>
import RegrasAssociacoes from '../regrasacesso/RegrasComponent';
import PerfisServices from './services';

export default {
    components: {
        RegrasAssociacoes,
    },

    emits: ['atualizar-tem-regras-associadas'],

    data() {
        return {
            regrasAssociadas: [],
            regrasDisponiveis: [],
        };
    },

    methods: {
        obterRegras() {
            this.$store.dispatch('addRequest');
            PerfisServices.obterRegras(this.$route.params.id).then(response => {
                if (response && response.success) {
                    this.regrasAssociadas = response.data.regrasAssociadas;
                    this.regrasDisponiveis = response.data.regrasDisponiveis;
                    this.atualizarTemRegrasAssociadas();
                } else {
                    this.regrasAssociadas = [];
                    this.regrasDisponiveis = [];
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Perfis de Acesso',
                        detail: 'Erro ao obter regras de acesso',
                        life: 3000,
                    });
                }
                this.$store.dispatch('removeRequest');
            });
        },

        desassociar(regrasAssociadasSelecionadas) {
            this.$store.dispatch('addRequest');
            let regras = {
                perfilAcessoId: this.$route.params.id,
                regras: regrasAssociadasSelecionadas,
            };
            PerfisServices.desassociar(regras).then(response => {
                if (response && response.success) {
                    this.regrasAssociadas = response.data.regrasAssociadas;
                    this.regrasDisponiveis = response.data.regrasDisponiveis;
                    this.atualizarTemRegrasAssociadas();
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Perfis de Acesso',
                        detail: 'Regras de acesso desassociadas com sucesso',
                        life: 3000,
                    });
                } else {
                    this.obterRegras();
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Perfis de Acesso',
                        detail: 'Erro ao desassociar regras de acesso',
                        life: 3000,
                    });
                }
                this.$store.dispatch('removeRequest');
            });
        },

        associar(regrasDisponiveisSelecionadas) {
            this.$store.dispatch('addRequest');
            let regras = {
                perfilAcessoId: this.$route.params.id,
                regras: regrasDisponiveisSelecionadas,
            };
            PerfisServices.associar(regras).then(response => {
                if (response && response.success) {
                    this.regrasAssociadas = response.data.regrasAssociadas;
                    this.regrasDisponiveis = response.data.regrasDisponiveis;
                    this.atualizarTemRegrasAssociadas();
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Perfis de Acesso',
                        detail: 'Regras de acesso associadas com sucesso',
                        life: 3000,
                    });
                } else {
                    this.obterRegras();
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Perfis de Acesso',
                        detail: 'Erro ao associar regras de acesso',
                        life: 3000,
                    });
                }
                this.$store.dispatch('removeRequest');
            });
        },

        atualizarTemRegrasAssociadas() {
            this.$emit(
                'atualizar-tem-regras-associadas',
                this.regrasAssociadas.length > 0
            );
        },
    },

    mounted() {
        this.obterRegras();
    },
};
</script>
